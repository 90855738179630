import React from 'react';
import Header from '../Components/Base/Header';
import ServiceCard from '../Components/ServiceCard';
import styled from 'styled-components';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import LymyxEasyIcon from '../Assets/Icons/lymyx_easy_icon.png'
import LymyxPayIcon from '../Assets/Icons/lymyx_pay_icon.png'
import LymyxStoresIcon from '../Assets/Icons/lymyx_stores_icon.png'
import { useTranslation } from 'react-i18next';
import FormatTextDirection from '../Functions/FormatTextDirection';
import { useAppContext } from '../Context';
import Constants from '../Config/Constants';

const PageContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ffffff, #fdfdfd); /* Very bright gradient */
`;

const Description = styled.p`
    font-size: 20px;
    color: #333;
    margin: 10px 0;
    line-height: 1.6;
    max-width: 800px;
    text-align: center; /* Center the description */
`;

const Title = styled.h2`
    font-size: 28px;
    color: #5f6368; /* Cooler title color */
    margin: 20px 0 10px;
`;

const SocialMediaContainer = styled.div`
    display: flex;
    gap: 15px;
    margin: 20px 0;
`;

const SocialMediaButton = styled.a`
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    background-color: ${props => props.bgColor};
    color: white;
    padding: 10px 20px;
    border-radius: 10px; /* Rounded corners for social media buttons */
    text-decoration: none;
    font-size: 14px;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${props => props.hoverColor};
    }

    svg {
        margin-right: 10px;
    }
`;

const Base = () => {
    const { t, i18n } = useTranslation();

    const { 
        language, setLanguage,
        languageDirection, setLanguageDirection,
        
    } = useAppContext();
     
    const services = [
        {
            title: 'Lymyx Pay',
            titleColor: '#446eb5',
            description: "Our digital wallet lets you handle all your financial needs with ease. Exchange currencies, make secure payments, and integrate with our API for smooth transactions. Simplify your finances today",
            icon: LymyxPayIcon,
            websiteLink: 'https://lymyx.net/pay',
            androidLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.pay',
            iosLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.pay',
        },
        /*{
            title: 'Lymyx Easy',
            titleColor: '#307aff',
            description: "The all-in-one super app designed to simplify your life. With Easy, you can travel and transport anything, anytime, anywhere. Whether it’s a quick ride or moving goods, Easy makes it simple and convenient.",
            icon: LymyxEasyIcon,
            websiteLink: 'https://lymyx.net/easy',
            androidLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.easy',
            iosLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.easy',
        },*/
        {
            title: 'Lymyx Stores',
            titleColor: '#2f5b93f2',
            description: "Easily create your own store, list products, and start selling in just a few steps! Our app lets users browse stores, place orders, and buy what they need—all from the convenience of their phone. Whether you're a seller or a shopper, this app makes online shopping simple and efficient.",
            icon: LymyxStoresIcon,
            websiteLink: 'https://lymyx.net/stores',
            androidLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.stores',
            iosLink: 'https://play.google.com/store/apps/details?id=com.ouailamarramzi.stores',
        },
    ];

    return (
        <>
            <Header />

            <PageContainer>

                <Title>{t("About Us")}</Title>
                <Description dir={languageDirection}>
                    {t("Welcome to Lymyx, where we provide innovative solutions for all your needs.")}
                    <br/>
                    {/*{t("Our services are designed to be fast, secure, and user-friendly, ensuring a seamless experience.")}
                    <br/>*/}
                    {t("At Lymyx, we offer a wide range of services tailored to meet the diverse needs of our global clients.")}
                    <br/>
                    {t("Join us as we strive to redefine excellence in the digital landscape, making your journey easier and more efficient.")}
                </Description>

                <SocialMediaContainer>
                    <SocialMediaButton 
                        href={Constants.INSTAGRAM_URL}
                        bgColor="#E4405F" 
                        hoverColor="#C13584"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaInstagram /> {t("Instagram")}
                    </SocialMediaButton>
                    <SocialMediaButton 
                        href={Constants.FACEBOOK_URL}
                        bgColor="#3b5998" 
                        hoverColor="#2d4373"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaFacebook /> {t("Facebook")}
                    </SocialMediaButton>
                </SocialMediaContainer>

                <Title>{t("Our Services")}</Title>
                {services.map((service, index) => (
                    <ServiceCard key={index} service={service} />
                ))}

                
            </PageContainer>
        </>
    );
};

export default Base;
